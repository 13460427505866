import Header from "./components/Header";
import HeroEight from "./hero/HeroEight";
import FeaturesOne from "./features/FeaturesOne";
import StatsOne from "./stats/StatsOne";
import { useEffect } from "react";
import TestimonialsOne from "./testimonials/TestimonialsOne";
import HeaderWhite from "./components/HeaderWhite";
import CTAOne from "./cta/CTAOne";
import PricingOne from "./pricing/PricingOne";
import FooterThree from "./footer/FooterThree";
import TestimonialsTwo from "./testimonials/TestimonialsTwo";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderWhite />
      <HeroEight />
      <StatsOne />
      <FeaturesOne />
      <TestimonialsTwo />
      {/* <PricingOne /> */}
      <CTAOne />
      <FooterThree />
    </>
  );
};

export default Main;
